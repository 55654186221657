// extracted by mini-css-extract-plugin
export var aboutContent = "index-module--aboutContent--v18ba";
export var aboutSection = "index-module--aboutSection--mHIvP";
export var accent = "index-module--accent--zI468";
export var accentShape = "index-module--accentShape--9v3KY";
export var active = "index-module--active--P-Kou";
export var contactContent = "index-module--contactContent--2wl7j";
export var contactLink = "index-module--contactLink--Jj42x";
export var contactLinks = "index-module--contactLinks--WILA+";
export var contactSection = "index-module--contactSection--Um+bA";
export var container = "index-module--container--2GuoF";
export var content = "index-module--content--7WlJI";
export var header = "index-module--header--IyPau";
export var headerContent = "index-module--headerContent--G1JGY";
export var section = "index-module--section--SkAcW";
export var skillsContent = "index-module--skillsContent--hWqvf";
export var skillsSection = "index-module--skillsSection--2blZ0";
export var workContent = "index-module--workContent--OjqXF";
export var workSection = "index-module--workSection--Kd7qo";